import React from 'react';
export let heading = "Discover A Beautiful Place With US";
export let subHeading = "Would you explore nature paradise in the world, let's find the best destination in world with us";
export const Navbar = (props) => {
    return (
      <>
      
      <div className="navbar-container">
            <div className="company-logo">
              <h3 className="display-6">Travelious</h3>
            </div>
              <ul className="nav">
                  <li className="nav-item ">
                    <a className="nav-link active" aria-current="page" href={props.Review}>Review</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={props.tips}>Tips</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link"  href={props.alerts}>Alerts</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link"  href={props.blog}>Blog</a>
                  </li>
          </ul>
          <div className="sign-in">
            <button className="btn btn-light">Sign In</button>
          </div>
      </div>
      </>
    );
}