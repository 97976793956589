import React from 'react';
export const LocationAndTravelers = (props) => {
    return (
        <div className="locationAndtravelers">
            <div className="locationAndtravelers-icon"><span class="material-symbols-outlined">location_on</span></div>
            <div></div>
            <div className="locationAndtravelers-icon"><span class="material-symbols-outlined">person</span></div>
            <div></div>
        </div>
    );
}