import React from 'react';
import './App.css';
import { Navbar, heading, subHeading } from './components/navBar';
import { Homebg } from './components/homebg';
import { Heading } from './components/heading';
import { Search } from './components/search';

function App() {
  return (
    <>
    <Navbar />
    <Homebg url= "https://container-news.com/wp-content/uploads/2021/04/Picture-1.jpg"/>
    <Heading heading= {heading} subheading = {subHeading}/>
    <Search />
    </>
  );
}

export default App;

