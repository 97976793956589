import React from 'react'

export const Heading = (props) => {
    return (
        <div className="heading-container">
            <div className="heading">
            <h1 className="display-1 ">{props.heading}</h1>
            <p className="display-7">{props.subheading}</p>
        </div>
        </div>
        
    );
}