import React from 'react';
import { LocationAndTravelers } from './travelDetails';

const flightIcon = <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAAXNSR0IArs4c6QAAASNJREFUOE/Fk00OgjAQhafEzaR4B7wJnEQ9iXoSvYl4EnsHMd3YUaY/WBpATEzshkDLN2/mvQr44RI/ZMH/Yfp2K0GIHQhRo5SH0N3Xyiwoy84eoIBoi8tlze8dTDfNxlZzy27C83nxTwWLhYLHo4hAdqtV1jHeMK0LMObYSi9nm0JUBVU9ZQGgGUrECjeT0AQ0CJsJ3ceDn2VAMuxY6Aml3KbKR92cALExNeZ5NQrT9/uxdZGHz06q9uA+OXyK5qhQytUgzA99PQBw54kqGw0izlfBxUZhvaEb49LtfrKgYL/PIncAkGUrROQOujU4M6/0miqIvveKfHRTN83ZBjjJU6fuq5yFO5jCXKivaZHPoTWmxDxnF3uL203nNQmbvEojmy93l5sUB8tPFAAAAABJRU5ErkJggg==" alt=''/>;
// const hotelIconLight = <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAAXNSR0IArs4c6QAAAQ9JREFUOE/NlE1OwzAQhd/Yzo9vkQ0Sx6BixS3aJXdAgkrcgSW9BStEuQUSC3ILp4ntoY5qCeWvSumi3tl+8/m9sWXCGQedkYULhxljCgUUFii11uWp0duYdV0vifkV3i8SrbddWNjvrpFzZVd7FNa6FuJnyC0DpfN+EdP0YEqpxjr3GIqVlGtrbQIhPvbzp73zzwhlKQswt7oI7MEgxAuA60PRF7y/DzAmWqVpuvnrsDHmJh6UZNm6D5PyDcx5W0RUwbm7MVhsAQObNMtWQ85uATwcHDzD+/cxWNA0ux2PwsINVVV1FYR5nn/HKEMxp2EnPrBhZ8xbJpr1aAlYTsacY/Boz/4Fm1M8pb3cL+gXZuzRFIwYc9QAAAAASUVORK5CYII=" alt=''/>;
const hotelIconDark = <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANpJREFUSEvtlDEKAjEQRd+2FuIFvIFHUEHExpt4CsHWwtLeS9gJokew9g4W2q4bSSBCkhnJxmoXUu3kvz9/Zrei8FMV1qcDiAn/NaLa2mkV6otpAGdgGsjlBdyBLXDw3/8KcCZS2W+AtSuIAUbAHhgDV2AF3JqLsS77wALYAUNgCRwNJAa4WHFnxEAmCYCrmzURnuyZpwChKIwZaU494Ak8gEGJDozml4m2ZuAPXQWIbYkUkaoD8fNXFnzSCUWkvC+WRQG5v4qsGYi2tVukEVItQm4copEOIEb0BlbcMRk1jyPqAAAAAElFTkSuQmCC" alt=''/>

export const Search = () => {
    return (
        <div className="search-container">
            <div className="search-child">
                <div className="search-child-1">
                    <button className="btn btn-outline-primary button-width active">{flightIcon} Flight</button>
                    <button className="btn btn-outline-primary button-width">{hotelIconDark} Hotel</button>
                </div>
                <div className="search-child-2">
                    <div className="search-child-2-child">
                      <LocationAndTravelers />  
                    </div>
                    <div className="search-child-2-child"></div>
                    <div className="search-button"></div>
                </div>
            </div>
        
    </div>
    );
}